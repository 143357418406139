$red: #f11930;
$light-red: #ffe5e5;
$yellow: rgb(255, 193, 48);
$sky-blue: #0c9fe1;
$blue: #327cbc;
$slate-blue: #7e84a3;
$slate-blue-light: #7e84a41a;
$pure-blue: #0091ea;
$green: #50a750;
$light-green: #1FD286;
$lightest-green: #e9fbf3;
$lime-green: #0cd782;
$dark-lime-green: #0ab16b;
$white: #ffffff;
$black: #000000;
$black-text: #41464b;
$light-grey: #f5f5f5;
$grey-table-head: #f5f6fa;
$grey: #bfbfbf;
$grey-chip-background: #bfbfbf1f;
$almost-grey: #b7b7b7;
$very-light-grey: #e0e0e0;
$shade-grey :#C4C4C4;
$dark-grey: #707070;
$soft-grey: #e0e0e0;
$very-dark-grey: #333333;
$not-black: #232323;
$transparent-black: #00000029;
$background-color: #f4f7fc;
$text-button-secondary: #5a607f;
$border-button-secondary: #d7dbec;
$dotted-border: #d0d6e8;
$very-dark-blue: #062045;
$darker-blue: #000736;
$deep-blue: #081e50;
$dark-blue: #171725;
$purple: #7070bf;
$dark-purple: #5f5fa7;
$strong-cyan: #00a1cb;
$dark-strong-cyan: #0091ba;
$warning: #c38924;
$warning-border: #ffca4d;
$warning-background: #ffca4d40;
$user-menu: #7e84a8;

$plexo-green: #cecf00;
$plexo-light-green: #dedf4a;
$plexo-blue: #004889;
$plexo-red: #ce3535;
$plexo-yellow: #e89b1b;

$primary: #00b2b8;
$primary-shade: #00b2b816;
$primary-light: #00d3dc;
$primary-midtone: #12868a;
$primary-dark: #187177;
$primary-disabled: #00b2b880;
$transparent-primary: rgba(241, 25, 48, 0.9);
$secondary: #000c55;
$secondary-main: #004889;
$transparent-yellow: rgba(255, 193, 48, 0.2);
$input-border: rgba(0, 0, 0, 0.23);
$border-color: #9cbffb;
$icon-color: #8b8b8b;
$icon-hover: rgba(0, 0, 0, 0.04);
$mobile-overlay: #41464b80;
$divider-color: #41464b;
$divider-horizontal: #ececec;
$box-shadow-small-card-color: #15223214;
$yellow-warning-border: #DEA110;
$yellow-warning-text: #C38900;
$yellow-warning-background: #FFFAED;

$mobile: 'only screen and (max-width: 768px)';
$tablet: 'only screen and (min-width: 769px) and (max-width: 1280px)';
$below-desktop: 'only screen and (max-width: 1279px)';
$desktop: 'only screen and (min-width: 1280px)';
$large-desktop: 'only screen and (min-width: 1441px)';

$header-height: 68px;
$header-content-height: 68px;
$white-line-height: 20px;
$footer-height: 60px;
$content-width: 1200px;
$normal-text-weight: 400;
$semi-bold-text-weight: 500;
$bold-text-weight: 600;
$title-text-weight: 700;

$header-shadow: 0 0.0625rem 0.1875rem 0
  rgba(
    $color: $slate-blue,
    $alpha: 0.2,
  );

$box-shadow: 0px 0px 15px 0px #7e84a312;
$box-shadow-small: 0px 1px 4px 0px #7e84a31a;
$box-shadow-dark: 0px 1px 4px 0px #1522321f;
$public-header-shadow: 0 0.0625rem 0.1875rem $white;
$box-shadow-dropdown-menu: 0px 2px 15px 0px #7e84a31f;
$box-shadow-floating-buttons: 0px 1px 6px 0px rgba(0, 0, 0, 0.161);
$box-shadow-small-card: 0px 1px 4px 0px $box-shadow-small-card-color;

:export {
  backgroundColor: $background-color;
  red: $red;
  lightRed: $light-red;
  yellow: $yellow;
  skyBlue: $sky-blue;
  blue: $blue;
  slateBlue: $slate-blue;
  slateBlueLight: $slate-blue-light;
  green: $green;
  lightGreen: $light-green;
  lightestGreen: $lightest-green;
  white: $white;
  warning: $warning;
  warningBorder: $warning-border;
  warningBackground: $warning-background;
  lightGrey: $light-grey;
  veryLightGrey: $very-light-grey;
  greyTableHead: $grey-table-head;
  grey: $grey;
  greyChipBackground: $grey-chip-background;
  almostGrey: $almost-grey;
  darkGrey: $dark-grey;
  softGrey: $soft-grey;
  veryDarkGrey: $very-dark-grey;
  shadeGrey: $shade-grey;
  notBlack: $not-black;
  black: $black;
  blackText: $black-text;
  darkBlue: $dark-blue;
  transparentBlack: $transparent-black;
  plexoGreen: $plexo-green;
  plexoLightGreen: $plexo-light-green;
  plexoBlue: $plexo-blue;
  plexoRed: $plexo-red;
  plexoYellow: $plexo-yellow;
  yellowWarningBorder: $yellow-warning-border;
  yellowWarningText: $yellow-warning-text;
  yellowWarningBackground: $yellow-warning-background;
  primary: $primary;
  primaryShade: $primary-shade;
  primaryLight: $primary-light;
  primaryMidtone: $primary-midtone;
  primaryDark: $primary-dark;
  primaryDisabled: $primary-disabled;
  textButtonSecondary: $text-button-secondary;
  borderButtonSecondary: $border-button-secondary;
  transparentPrimary: $transparent-primary;
  secondary: $secondary;
  secondaryMain: $secondary-main;
  transparentYellow: $transparent-yellow;
  limeGreen: $lime-green;
  darkLimeGreen: $dark-lime-green;
  darkPurple: $dark-purple;
  deepBlue: $deep-blue;
  veryDarkBlue: $very-dark-blue;
  darkBlue: $dark-blue;
  purple: $purple;
  strongCyan: $strong-cyan;
  darkStrongCyan: $dark-strong-cyan;
  pureBlue: $pure-blue;
  inputBorder: $input-border;
  borderColor: $border-color;
  iconColor: $icon-color;
  dottedBorder: $dotted-border;
  mobile: $mobile;
  tablet: $tablet;
  belowDesktop: $below-desktop;
  desktop: $desktop;
  largeDesktop: $large-desktop;
  headerHeight: $header-height;
  headerContentHeight: $header-content-height;
  whiteLineHeight: $white-line-height;
  footerHeight: $footer-height;
  contentWidth: $content-width;
  normalTextWeight: $normal-text-weight;
  semiBoldTextWeight: $semi-bold-text-weight;
  boldTextWeight: $bold-text-weight;
  titleTextWeight: $title-text-weight;
  headerShadow: $header-shadow;
  boxShadow: $box-shadow;
  boxShadowSmall: $box-shadow-small;
  boxShadowDark: $box-shadow-dark;
  publicHeaderShadow: $public-header-shadow;
  boxShadowDropdownMenu: $box-shadow-dropdown-menu;
  boxShadowFloatingButtons: $box-shadow-floating-buttons;
  boxShadowSmallCard: $box-shadow-small-card;
  iconColor: $icon-color;
  dividerColor: $divider-color;
  dividerHorizontal: $divider-horizontal;
}
