.home {
  background: url('../assets/background.png') no-repeat;
  background-position: bottom 0 right 0;
  background-color: $secondary;
  margin: 0 auto;
  flex-grow: 1;
  width: 100%;
  min-height: 600px;
  overflow: hidden;
  margin-top: $header-content-height;

  .home-content {
    display: flex;
    align-items: center;

    .texts {
      width: 50%;
      padding: 8.75rem 5rem 5rem 6.875rem;

      .title {
        font: normal normal bold 54px/58px Ubuntu;
        letter-spacing: 0;
        color: $white;

        .green-text {
          color: $primary;
        }
      }

      .subtitle {
        margin: 1.6rem 0;
        font: normal normal normal 22px/38px Ubuntu;
        letter-spacing: 0;
        color: $white;
      }

      img {
        position: relative;
        z-index: 2;
        width: 150%;

        @media #{$below-desktop} {
          display: none;
        }
      }
    }

    .image {
      width: 50%;
      display: flex;
      justify-content: center;
      z-index: 1;

      img {
        height: 90%;
        width: auto;
        position: absolute;
        bottom: 0;
        right: 6%;
      }
    }

    .home-buttons {
      display: none;
    }
  }

  @media #{$below-desktop} {
    .home-content {
      height: auto;
      flex-direction: column;
      text-align: center;
      min-height: calc(100vh - #{$header-content-height});

      .texts {
        width: 100%;
        padding: 2rem 2rem 0;

        .title {
          font-size: 2.0625rem;
          line-height: 1.2;
        }

        .subtitle {
          margin-bottom: 0;
          font-size: 1.125rem;
          line-height: 1.5;

          br {
            display: none;
          }
        }
      }

      .home-buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem 2rem 0;
        
        @media #{$tablet}{
          width: 50%;
        }
      }

      .image {
        width: 100%;
        margin-top: auto;
        margin-top: auto;
        align-self: flex-end;

        img {
          width: 100%;
          height: auto;
          position: relative;
          bottom: auto;
          right: auto;
        }
      }
    }
  }
  .outlined-home-button {
    color: $white;
    border: 1px solid $white;
    background-color: transparent;
  }
}

.header-content:has(.home-header-options) {
  @media #{$below-desktop} {
    justify-content: center;
  }
}

.home-header-options {
  button {
    height: 45px;
    margin-left: 1rem;
  }
  .outlined-home-button {
    color: $white;
    border: 1px solid $white;

    &:hover {
      background-color: $darker-blue;
    }
  }

  .contained-home-button {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: $primary-dark;
      border: 1px solid $primary-dark;
      color: white
    }
  }

  @media #{$below-desktop} {
    display: none;
  }
}
