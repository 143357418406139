.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 43.75rem;
  min-width: auto;
  max-width: 100%;
  margin: 0 auto;

  .profile-paper {
    @media #{$mobile} {
      padding: 1.25rem;
    }
  }

  h2 {
    font-weight: $semi-bold-text-weight;
    font-size: 1.25rem;
  }

  h4 {
    font-weight: $semi-bold-text-weight;
    color: $slate-blue;
    font-size: 1.0625rem;
    font-weight: 500;
  }

  form {
    label {
      color: $slate-blue;
    }
  }

  .divider {
    width: 100%;
  }

  .links {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    a {
      cursor: pointer;
      user-select: none;
      margin: 0.3rem 0;
    }
  }

  .image-error {
    color: $red;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .change-password-button {
    margin-top: 1rem;
  }
}

.upload-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 0;
  margin-bottom: 1rem;
  height: 9.375rem;

  @media #{$mobile} {
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .img-container {
    display: flex;
    flex-direction: column;
    border: 1px dashed $grey;
    border-radius: 0.375rem;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
    align-self: flex-end;

    img {
      height: auto;
      min-width: 2.8125rem;
      width: auto;
      max-height: 100%;
      border-radius: 0.25rem;
      padding: 0.5rem 0;
    }

    @media #{$mobile} {
      margin-bottom: 1rem;
      width: 100%;
      height: 100%;
      align-self: center;

      img {
        max-height: 6.25rem;
      }
    }
  }

  .image-instructions {
    color: $slate-blue;
    text-align: center;
    width: 70%;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }

  .no-image {
    img {
      min-width: unset;
      width: 2rem;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: flex-end;

    @media #{$mobile} {
      padding-top: 1rem;
      width: 100%;
      align-items: center;
    }

    .action {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 2.375rem;
      width: 10.9375rem;

      svg {
        margin-right: 0.3rem;
      }

      span {
        padding-top: 0.125rem;
      }
    }

    .buttonable-span {
      border: 1px solid $border-button-secondary;
      border-radius: 0.375rem;
      padding: 0.475rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text-button-secondary;
      height: 2.375rem;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        background-color: $border-button-secondary;
      }

      @media #{$mobile} {
        width: 100%;
      }
    }

    .required-message {
      width: 100%;
      font-size: 0.8rem;
      color: $plexo-red;
    }
  }

  label {
    box-sizing: border-box;
    padding: 0;
    border-radius: 0.1875rem;
    margin-left: 1rem;
    font-size: 1rem;
    font-weight: normal;
    display: flex;
    align-content: flex-start;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    user-select: none;

    @media #{$mobile} {
      margin-left: 0;
      width: 100%;
    }
  }

  .disabled-label {
    cursor: auto;
    box-sizing: border-box;
  }

  input[type='file'] {
    visibility: hidden;
    position: absolute;
    box-sizing: border-box;
  }
}

.upload-title {
  font-weight: $semi-bold-text-weight;
  font-size: 0.875rem;
  color: $slate-blue;
  margin: 0; 
}

.upload-helper {
  font-weight: $normal-text-weight;
  font-size: 0.875rem;
  color: $slate-blue;
  margin: 0.25rem 0 0; 
}
