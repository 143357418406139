.consumer-warnings {
  width: max-content;
  max-width: 70%;
  padding: 1.2rem;
  border-radius: 0.4rem;
  border: 1px solid $yellow-warning-border;
  background-color: $yellow-warning-background;
  color: $yellow-warning-text;
  margin: 0 auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
    path {
      fill: $warning;
    }
  }

  @media #{$mobile} {
    flex-direction: column;
    max-width: 100%;
    text-align: center;
  }

  .MuiTypography-subtitle2 {
    color: $yellow-warning-text;
    font-size: 0.875rem;
    font-weight: $normal-text-weight;
    margin-left: 0.6rem;

    @media #{$mobile} {
      margin-left: 0;
      margin-top: 0.6rem;
    }
  }
}

.consumer-card {
  width: 500px;
  max-width: 100%;
  border: 1px solid $light-grey;
  margin: 0 auto;

  .heading,
  .error-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    border-radius: 4px 4px 0 0;
    background-color: $primary;
    color: white;

    h4,
    h6 {
      text-align: center;
    }

    @media #{$mobile} {
      padding: 2rem 1rem;
    }
  }

  .installments-heading {
    position: relative;

    .go-back-div {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 50px;
      display: flex;
      align-items: center;
      padding-left: 1rem;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        cursor: pointer;
      }
    }
  }

  .error-heading {
    background-color: $plexo-red;
  }

  .card-body,
  .error-body {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media #{$mobile} {
      padding: 1rem;
    }

    .card-image {
      height: 50px;
      width: auto;
      margin: 1rem;
    }

    .destination {
      color: $not-black;
    }

    .divider {
      margin: 1rem 0;
      width: 100%;
    }

    .amount {
      color: $not-black;
      margin: 0.2rem 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      .currency,
      .iva,
      .small-iva {
        font-size: 1.2rem;
        margin: 0.4rem;
        color: $dark-grey;
      }

      .small-iva {
        font-size: 1rem;
      }
    }

    .reference-text,
    .amount-alone {
      margin-bottom: 1.4rem;
    }

    .continue-button {
      font-size: 1.2rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .reference {
      margin-top: 1rem;
      margin-bottom: 0.2rem;
    }

    .payment-reference {
      margin-top: 1rem;
      color: $slate-blue;
      text-align: center;
    }
  }

  .card-body {
    .issuers-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
      row-gap: 1rem;
      padding: 1rem 0;
      width: 100%;

      .issuer-card {
        border: 1px solid $input-border;
        border-radius: 4px;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .issuer-image {
          max-width: 70%;
          max-height: 40px;
        }
      }

      .issuer-card:hover {
        background-color: $light-grey;
        cursor: pointer;
      }

      .disabled-issuer-card {
        opacity: 30%;
        background-color: $light-grey;
      }
      .disabled-issuer-card:hover {
        background-color: $light-grey;
        cursor: auto;
      }

      .selected-issuer-card {
        border: 1px solid $primary;
      }
    }

    .installments-card-body {
      width: 100%;

      .amount-div {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .amount {
          font-size: 1.7rem;

          .currency {
            font-size: 1rem;
          }

          .iva {
            font-size: 0.8rem;
          }
        }
      }

      .installments-row {
        display: flex;
        justify-content: space-between;

        .issuer-card {
          border: 1px solid $input-border;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 56px;
          margin: 16px 8px 8px 0;
          min-width: 140px;

          .issuer-image {
            max-height: 36px;
            max-width: 100%;
            width: auto;
            height: auto;
            padding: 0 1rem;
          }
        }
      }

      .installments-button {
        margin-top: 1rem;
      }
    }
  }

  .error-body {
    button {
      margin-top: 2rem;
    }
  }

  .checkout {
    align-items: flex-start;
    padding: 2rem 5rem;

    @media #{$mobile} {
      padding: 2rem 1rem;
    }

    .business,
    .issuer,
    .payment {
      display: flex;
      flex-direction: row;
      align-items: center;

      .details {
        margin-left: 1rem;
      }
    }

    .divider {
      width: 100%;
      margin: 1.2rem 0;
    }

    .business {
      img {
        height: 30px;
        width: auto;
        margin: 0;
      }

      h6 {
        line-height: 1.1rem;
      }
    }

    .issuer {
      img {
        height: 24px;
        width: auto;
        margin: 0;
      }
    }

    .payment {
      width: 100%;
      justify-content: space-between;

      .amount {
        margin: 0;
      }
    }

    .form {
      width: 100%;

      button {
        margin-top: 1.2rem;
      }
    }
  }

  .amount-form {
    margin-top: 1rem;

    .pay-text {
      margin-top: 1rem;
    }

    button {
      margin-top: 1rem;
    }
  }

  iframe {
    border: none;
    max-width: 100%;
  }
}

.plexo-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .plexo-payment-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    width: 31.125rem;

    @media #{$mobile} {
      width: 100%;
    }

    h4 {
      font-size: 1.125rem;
      font-weight: $semi-bold-text-weight;
      color: $secondary;
    }

    .secondary-button {
      color: #5a607f;
      font-size: 1rem;
      padding: 0.5rem 1.5rem;
      background-color: $white;
      border: 1px solid $border-button-secondary;
      border-radius: 6px;
      cursor: pointer;
      line-height: 1.575rem;

      &:hover {
        background-color: $border-button-secondary;
      }
    }
  }
}

.result-card {
  padding-top: 0 !important;
  margin: 0 auto;

  h4,
  p {
    text-align: center;
  }

  @media #{$desktop} {
    width: 31.25rem !important;
  }
}

.login-redirection {
  margin-top: 2rem !important;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
