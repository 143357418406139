.faqs {
  h4 {
    color: $black;
    font-weight: 500;
    font-size: 1rem;
  }

  .screen-header {
    button {
      width: 100px;
      font-weight: 400;
      font-size: 14px;
    }
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.4rem;
  }

  .faqs-list {
    padding: 2rem;
    background-color: $white;
    box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
    border-radius: 9px;

    .faq {
      .question {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        margin-bottom: 0.6rem;
        font-size: 1rem;
        color: $slate-blue;
      }

      .answer {
        font-size: 0.9rem;
        color: $slate-blue;
        font-weight: 400;
      }

      .final-question {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        font-size: 1rem;
        color: $slate-blue;
      }

      .divider {
        margin: 1rem 0;
      }
    }
  }

  .contact {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
      margin-top: 1rem;
      font-size: 0.8rem;
      box-shadow: none;
    }
  }
}

.contact-form {
  width: 400px;

  @media #{$mobile} {
    width: 100%;
  }

  .divider {
    margin: 1rem 0 !important;
    width: 100%;
  }

  button {
    margin-top: 1rem;
    width: 100%;
  }
}
