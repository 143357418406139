.payment-go-back,
.logout {
  h2 {
    height: 18px;

    @media #{$mobile} {
      height: 30px;
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0.75rem 1.25rem;
  }

  .content {
    padding: 0;
    padding-bottom: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $dark-grey;
  }

  .modal-title {
    width: 90%;
    margin-top: 1.2rem;
    color: $black;
    text-align: center;
    font-size: 1rem;
    font-weight: $title-text-weight;
  }

  .close-icon {
    fill: $icon-color;
    cursor: pointer;
  }

  .actions {
    margin-top: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .cancel-button {
      font-size: 0.9rem;
      font-weight: $normal-text-weight;
      min-width: 0;
    }

    .close-button,
    .close-sesion-button {
      font-size: 0.9rem;
      margin-left: 1rem;
      font-weight: $normal-text-weight;
      min-width: 0;
    }
  }
}

.payment-go-back {
  .MuiDialog-paperWidthXs {
    max-width: 34.3rem;
  }

  .text {
    text-align: center;

    @media #{$mobile} {
      margin-top: 1rem;
      width: 80%;
    }
  }

  .close-button {
    text-decoration: none;
    color: $white;
    background-color: $primary;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border: 1px solid $primary;
    line-height: 1.75;

    &:hover {
      background-color: $white;
      color: $primary;
    }
  }

  @media #{$mobile} {
    text-align: center;
  }
}
