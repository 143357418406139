.landing {
  width: 70vw;

  .link {
    background-color: $primary;
    color: $white;
    text-decoration: none;
    padding: 0.5rem 2rem;
  }

  .subtitle {
    max-width: 32.1875rem;
    margin: 0;
  }

  .paragraph {
    font-size: 1.125rem;
    color: $secondary;
  }

  .content-subtitle {
    margin-bottom: 1.5rem;

    @media #{$mobile} {
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  .download-button {
    min-width: 15.625rem;
  }
}

.confirm-email {
  display: flex;
  justify-content: center;

  @media #{$tablet} {
    justify-content: center;
  }

  button {
    margin-top: 2rem;
  }
}

.centered-text {
  text-align: center;
  padding: 0 1rem;
}

.expired-link-row {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .MuiTypography-body1 {
    text-align: end;
  }
}

.amount-row {
  margin-top: 1rem;

  .amount {
    font-size: 1.125rem;
    font-weight: $title-text-weight;
  }
}

.iva {
  margin-left: 0.5rem;
  font-size: 0.625rem;
  color: $slate-blue;
  font-weight: $normal-text-weight;
  font-style: italic;
}
