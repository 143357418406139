.dialog {
  .MuiDialog-paper {
    border-radius: 0.25rem;

    @media #{$mobile} {
      border-radius: 0.4rem;
    }

    &.MuiDialog-paperWidthXs {
      max-width: 46.75rem;
      width: 100%;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .dialog-title {
    background-color: $white;
    padding: 0.6875rem 1.6rem 0rem 1.6rem;

    h2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      width: 100%;

      .icon {
        fill: $icon-color;
        cursor: pointer;
        user-select: none;
      }
    }
  }

  .dialog-content {
    padding: 1rem 3rem 2.5rem;

    p {
      padding: 0.5rem;
    }

    @media #{$mobile} {
      padding: 0.6rem 1.6rem;
    }

    .plain-text {
      margin: 0.6rem 0;
    }

    .link {
      margin-top: 0.6rem;
    }
  }

  .dialog-actions {
    padding: 1rem 3rem 2rem 3rem;

    @media #{$mobile} {
      padding: 0.6rem 1.6rem;
      padding-bottom: 1.6rem;
    }
  }

  .email-dialog-actions {
    flex-direction: column;
    gap: 1.5rem;

    & > :not(:first-child) {
      margin-left: 0;
    }
  }

  .date-dialog-actions {
    padding: 0 2rem 2rem 2rem;

    @media #{$mobile} {
      padding: 0.6rem 1.6rem;
      padding-bottom: 1.6rem;
    }
  }
}

.dialog.change-password .MuiDialog-paperWidthXs,
.dialog.disable-split-payments-dialog {
  max-width: 35rem;
}
