.form {
  width: 100%;
  max-width: 100%;

  a {
    font-size: 0.9375rem;
    color: $primary;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
      color: $primary-midtone;
    }
  }

  .actions-row,
  .many-actions-row {
    margin-top: 1.6rem;
  }

  .actions-row,
  .many-actions-row-login {
    margin-top: 0.5rem;
  }

  .form-row-first {
    margin-top: 0;
  }

  .many-actions-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9375rem;

    button {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .actions-row,
  .many-actions-row {
    margin-top: 1.6rem;

    @media #{$mobile} {
      margin-top: 0.5rem;
    }
  }

  .form-row-first {
    margin-top: 0;
  }

  .many-actions-row-login {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9375rem;

    @media #{$mobile} {
      flex-direction: column;
      align-items: flex-start;
    }

    button {
      padding-left: 2rem;
      padding-right: 2rem;

      @media #{$mobile} {
        margin-top: 2.5rem;
        width: 100%;
      }
    }
  }

  .form-row {
    justify-content: center;

    .row-field {
      margin: 0 0.6rem;
      width: 400px;
    }
  }

  .row-field-negative-margin {
    input {
      margin-left: -0.5rem;
    }
  }

  .register-eye-button {
    top: 55%;
  }

  .button-row-field {
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-end;

    @media #{$mobile} {
      button {
        width: 100%;
      }
    }
  }

  .button-full-width {
    width: 100%;
  }
}

.texts-row {
  margin: 0.4rem 0;
  display: flex;
  align-items: center;
  gap: 0.125rem;

  p {
    margin: 0;
  }

  .example {
    color: #222222;
  }

  @media #{$below-desktop} {
    align-items: flex-start;
  }
}

.payment-method-form {
  .paper-container {
    padding-top: 1.65rem;
  }

  h3 {
    color: $slate-blue;
    font-size: 1.0625rem;
    font-weight: $semi-bold-text-weight;
    padding: 0;
    margin-bottom: 2rem;
  }

  h4 {
    color: $black;
    font-size: 1.0625rem;
    font-weight: $semi-bold-text-weight;
    padding: 0;
    margin-bottom: 2rem;
  }

  h6 {
    padding: 0;
    margin-bottom: 1rem;
  }

  h6,
  .soft-descriptor {
    color: $slate-blue;
  }

  .first-payment-method-text {
    font-size: 0.875rem;
    font-weight: $semi-bold-text-weight;
    margin-bottom: 2rem;
    color: $icon-color;

    a {
      color: $primary;
      font-weight: $normal-text-weight;
    }
  }

  .installments-field {
    border: 1px solid $border-button-secondary;
    padding: 1rem;
  }

  .MuiFormControl-marginNormal {
    margin-bottom: 0;
    margin-top: 0;
  }

  .bank {
    border: 1px solid $input-border;
    border-radius: 4px;
    padding: 1.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    img {
      max-height: 30px;
      max-width: 100%;
      width: auto;
      height: auto;
    }

    &:hover {
      background-color: $light-grey;
    }
  }

  .selected-bank {
    border: 1px solid $primary;
    background-color: $light-grey;
  }
}

.issuers-list {
  display: grid;
  grid-template-columns: auto auto auto;

  @media #{$mobile} {
    grid-template-columns: auto;
  }
}

.form-title {
  padding: 1rem 3rem;

  @media #{$mobile} {
    padding: 0.6rem 1.6rem;
  }
}

.dialog.form-title {
  margin-bottom: 2rem;
}

.sign-in-toggle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  @media #{$mobile} {
    flex-direction: column;
  }
}
