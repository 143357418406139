.filter-container {
  flex-grow: 1;
  width: 15%;
  box-shadow: none;

  & fieldset {
    width: 100%;
  }

  @media #{$below-desktop} {
    width: 100%;
    margin-top: 1rem;
  }

  .form-label {
    font-size: 0.875rem;
    font-weight: $normal-text-weight;
    line-height: 1.5;
    color: $slate-blue;
  }

  .currency-filter-container {
    margin-bottom: 2rem;
  }

  .label {
    margin-right: 2rem;
    margin-bottom: -0.6rem;
  }

  .group {
    flex-direction: row;
    width: 100%;

    @media #{$below-desktop} {
      flex-direction: column;
    }
  }

  .select {
    width: 100%;
    background-color: $white;
    margin-top: 0.3125rem;
    border-radius: 6px;
    color: $icon-color;

    & div {
      padding: 0.65rem 0.75rem;
    }

    & p {
      padding: 0;
      margin: 0;
    }
  }

  .select-element {
    margin: 0;
  }

  .disabled {
    background-color: $grey-chip-background;
  }
}
