.configuration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;

  .configuration-paper {
    margin-bottom: 2rem;
    color: $slate-blue;
    .image-error {
      color: $red;
      margin-top: 0;
      margin-bottom: 1.5rem;
    }
  }

  .divider {
    margin: 16px 0;
    width: 100%;
  }

  h4 {
    width: 100%;
    color: $black;
    font-weight: $semi-bold-text-weight;
  }

  .business-config {
    .MuiTypography-body1 {
      color: $slate-blue;
      font-weight: $semi-bold-text-weight;
      font-size: 0.875rem;
    }
  }

  .helper-text {
    margin: 0;
    color: $icon-color;
    margin-bottom: 0.8rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .advanced-config-subtitle {
    margin: 2rem 0 1.25rem 0;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .advanced-config {
    padding-top: 1rem;
    margin-bottom: 1rem;
  }

  .upload-btn-wrapper {
    padding: 0;
    height: 7rem;
    margin-bottom: 1.5rem;

    @media #{$mobile} {
      height: auto;
    }

    .img-container {
      width: 65%;
      height: 6.5rem;
      align-self: flex-end;

      img {
        height: auto;
        min-width: 2.8125rem;
        width: auto;
        max-height: 6.5rem;
        border-radius: 0.25rem;
        padding: 0.5rem 0;
      }

      @media #{$mobile} {
        width: 100%;
        height: 100%;
        align-self: center;
      }
    }

    .no-image {
      img {
        min-width: unset;
        width: 2rem;
      }
    }

    label {
      justify-content: flex-end;
      width: auto;

      @media #{$mobile} {
        width: 100%;
      }
    }

    .actions {
      width: 30%;
      align-items: flex-end;
      align-self: flex-end;

      @media #{$mobile} {
        width: 100%;
        align-items: center;
      }
    }
  }

  .business-form-button {
    @media #{$mobile} {
      margin-bottom: 2rem;
    }
  }
}

.payment-methods-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16.25rem, 1fr));
  grid-gap: 1.2rem;
  width: 100%;
  margin: 1rem 0;

  .new-payment-method {
    display: flex;
    gap: 0.75rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px dotted $dotted-border;
    height: 100%;
    background-color: $white;
    cursor: pointer;
    user-select: none;
  }
  
  .disabled {
    opacity: 0.5;
    cursor: auto;
  }

  .payment-method {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    background-color: $white;
    box-shadow: 0 0.0625rem 0.1875rem 0 rgba($color: $slate-blue, $alpha: 0.2);
    cursor: pointer;
    user-select: none;

    .divider {
      width: 85%;
      margin: 0;
    }

    .payment-method-text {
      color: $black;
      font-size: 0.75rem;
      font-weight: $normal-text-weight;
    }

    .trash {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      padding: 0.5625rem 0.75rem;
      padding-bottom: 0;
      color: $grey;

      .icon {
        width: 1.875rem;
        cursor: pointer;
        user-select: none;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: $plexo-red;
        }
      }
    }

    img {
      height: auto;
      width: 6.25rem;
      margin-top: 0;
      margin-bottom: 0.8rem;
    }

    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0.8rem;

      h6 {
        color: $slate-blue;
      }
    }
  }

  .new-payment-method {
    padding: 1rem;
    justify-content: center;
  }
}

.payment-methods-list > *:only-child {
  width: 30%;
  margin-right: auto;
  
  @media #{$mobile} {
    width: 100%;
  }
}
