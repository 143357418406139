@import './variables.module';
@import './common';
@import './consumer';
@import './forms';
@import './landing';
@import './links';
@import './auth';
@import './payments';
@import './profile';
@import './modal';
@import './result';
@import './faqs';
@import './filters';
@import './users';
@import './configuration';
@import './home';

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ubuntu&subset=latin');

body {
  position: relative;

  &:has(.navigation-mobile-overlay) {
    overflow: hidden;
  }
}

.main {
  font-family: 'Rubik', sans-serif;
  background-color: $background-color;
  min-height: 100vh;
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:has(.landing) {
    .logo {
      display: block;
    }

    @media #{$desktop} {
      .header {
        display: none;
      }
    }
  }

  .content {
    flex-grow: 1;
    max-width: 100%;
    min-height: 600px;
    border-radius: 0.6rem;
    padding: 2.5rem;
    margin-top: $header-content-height;
    background-color: $background-color;
    height: auto;

    &:has(.landing) {
      background: url('../assets/background.png') no-repeat;
      background-image: url('../assets/light-background.svg');
      background-size: cover;
      background-color: $white;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:has(.configuration) {
      @media #{$mobile} {
        height: auto;
      }
    }

    @media #{$mobile} {
      padding: 1rem;
    }

    .dashboard {
      position: relative;
      margin: 0 auto;

      @media #{$below-desktop} {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out 100ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}
