.users {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;

  .divider {
    margin: 16px 0;
    width: 100%;
  }

  .main-button {
    font-size: 0.875rem;
    font-weight: $normal-text-weight;
  }

  .collaborators-list {
    display: flex;
    flex-direction: row;
    margin-bottom: 2.1875rem;

    .collaborator,
    .new-collaborator {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.2rem;
      padding: 0.8rem;
      background-color: $white;
      font-size: 1rem;
      box-shadow: 0 0.0625rem 0.1875rem 0 rgba($color: $slate-blue, $alpha: 0.2);

      .names {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .icon {
        display: flex;
        align-items: center;
        margin-right: 0.6rem;
        cursor: pointer;
        user-select: none;
      }
    }

    .new-collaborator {
      justify-content: flex-start;
      cursor: pointer;

      .icon {
        margin-right: 0.6rem;
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
  }
}

.users-mobile {
  .screen-title-mobile {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.25rem;
    align-items: center;
    margin-bottom: 1rem;

    h5 {
      margin: 0;
    }
  }

  .users-mobile {
    display: flex;
    flex-direction: column;
  }

  .user-card {
    background-color: $white;
    box-shadow: 0px 1px 4px 0px $box-shadow-small-card-color;
    margin-bottom: 1rem;
    padding: 0.8rem;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 2.5rem;
    }

    .vertical-divider {
      border-left: 1px solid $divider-color;
      opacity: 0.1;
      height: 1.6rem;
      margin-left: 0.6rem;
    }

    .horizontal-divider {
      border-bottom: 1px solid $divider-color;
      opacity: 0.1;
      margin: 0.5rem 0;
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }
  }
}
