.links {
  max-width: 100%;
}

.links-list {
  margin-top: 1rem !important;
}

.infinite {
  overflow: hidden !important;
}

.delete-icon svg {
  color: $grey !important;
}

.links-search-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.links-filters-container {
  margin-top: 2rem;
}
