.header {
  ul {
    list-style-type: none;
    margin: unset;
    padding: unset;
  }

  box-sizing: border-box;
  height: $header-height;
  background-color: $secondary;
  padding: 0 1rem;

  .header-content {
    height: $header-content-height;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $slate-blue;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 4rem;

    @media #{$tablet} {
      padding: 1rem;
    }

    @media #{$mobile} {
      padding: 0;
    }

    .logo {
      img {
        height: 2.875rem;
        width: auto;
      }
    }

    .logo,
    .user-menu {
      background-color: transparent;
      border: none;
      color: $user-menu;
      padding: 0;
    }

    img {
      height: 2.5rem;
      cursor: pointer;
      user-select: none;
    }

    img {
      height: 2.5rem;
      cursor: pointer;
      user-select: none;
    }

    .icon {
      height: 1rem;
      width: 1rem;

      @media #{$below-desktop} {
        display: block;
      }
    }

    .expand-icon {
      margin-left: auto;
      width: 1rem;
      transition: transform 0.4s;
    }

    .expand-icon.expanded {
      transform: rotate(-180deg);
    }

    .public-img {
      height: 4rem;
      width: auto;

      @media #{$below-desktop} {
        display: block;
      }
    }

    .header-options {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      height: $header-content-height;

      .indicator {
        background-color: $secondary-main;
        height: 0.1875rem;
        border-radius: 1rem;
      }

      .option {
        font-size: 1rem;
        margin-bottom: 1.15rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        color: $dark-blue;
        font-weight: normal;
        user-select: none;
        -webkit-transition: color 0.2s ease-in-out;
        transition: color 0.2s ease-in-out;
        text-decoration-line: none;
        padding: 0;
        background-color: transparent;
        border: none;
        font-family: 'Rubik';

        &:hover {
          .icon,
          svg {
            color: $secondary-main;
            path {
              fill: $secondary-main;
            }
          }
        }

        .icon,
        svg {
          color: $slate-blue;
          margin-right: 0.6rem;
          height: 1.2rem;
          width: 1.2rem;

          path {
            fill: $slate-blue;
          }
        }

        .svg-icon {
          height: 1.35rem;
          width: 1.35rem;

          &:hover {
            .mutable-on-hover {
              color: $secondary-main;
            }
          }
        }
      }

      .hidden-option {
        display: none;
      }

      .selected-option {
        .icon,
        svg {
          color: $secondary-main;

          path {
            fill: $secondary-main;
          }
        }
      }
    }

    .navigation-mobile-overlay {
      width: 100vw;
      background-color: $mobile-overlay;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      backdrop-filter: blur(1.5px);
      z-index: 1;
    }

    .navigation-mobile {
      button {
        background: unset;
        border: none;
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }

      .navigation-container {
        width: 60%;
        max-width: 500px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100dvh;
        background-color: white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-radius: 0;
        padding: 0 1rem 1rem 0;

        .divider {
          width: 100%;
          color: $slate-blue;
          opacity: 0.2;
        }

        .selected-option,
        .option {
          position: relative;
          color: $black;
          font-size: 1rem;
          display: flex;
          flex-direction: row;
          font-family: 'Rubik';
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.5rem 1rem;
          height: 3rem;

          .icon {
            margin-right: 0.6rem;
          }

          .no-icon {
            width: 1.625rem;
          }
        }

        .sub-option {
          font-size: 0.875rem;
          color: $black-text;
          height: 2.15rem;
        }

        .selected-option {
          background-color: $background-color;
          border-radius: 0 8px 8px 0;
          color: $plexo-blue;

          .icon {
            path {
              fill: $plexo-blue;
            }
          }
        }

        .navigation-border {
          position: absolute;
          left: 0;
        }

        .hidden-option {
          display: none;
          height: 0;
        }

        .selected-setting-option {
          background: $background-color;
        }

        .selected-profile-option {
          background: $background-color;
        }
      }

      .navigation-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1rem 0 1rem 1rem;
      }

      .navigation-options {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-grow: 1;
      }

      .logout-button-container {
        width: 100%;
        padding-left: 1rem;
      }

      .logout-button {
        border: 1px solid $border-button-secondary;
      }

      .burger-toggle {
        color: $slate-blue;
        display: none;

        @media #{$below-desktop} {
          display: block;
        }
      }
    }

    .configurations {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .divider {
        height: 2.25rem;
        margin: 0 0.8rem;
        width: 1px;
        background-color: $slate-blue;
        opacity: 0.2;
      }

      .user-menu {
        display: flex;
        flex-direction: row;
        align-items: center;

        .name {
          color: $very-dark-blue;
          font-size: 1rem;
          margin-right: 0.2rem;
        }
      }
    }

    .help {
      display: flex;
      flex-direction: row;
      align-items: center;
      user-select: none;
      border-color: $border-button-secondary;

      .icon {
        margin-right: 0.4rem;
      }
    }
  }
}

.iframe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  iframe {
    max-width: 100%;
    margin-top: 1rem;
    border: 2px solid $grey;
    border-radius: 1rem;

    @media #{$mobile} {
      margin-top: 0;
      border-radius: 1.6rem;
    }
  }
}

.dialog {
  h2 {
    height: 18px;
  }
}

.screen-container {
  width: 64vw;
  min-width: 45rem;
  margin: 0 auto;
  padding-bottom: 1rem;

  @media #{$mobile} {
    width: 100%;
    min-width: 100%;
  }

  .screen-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$mobile} {
      flex-direction: column;
      align-items: flex-start;
    }

    .screen-title {
      width: 100%;
    }
    
    .create-button {
      margin-left: 0.75rem;
    }

    button {
      min-width: 10rem;
      height: fit-content;
      padding: 0;

      @media #{$mobile} {
        width: 100%;
        height: unset;
        margin-top: 1rem;
      }
    }
  }
  .screen-divider {
    margin: 1.2rem 0 2rem 0;
  }

  &.screen-container-extended {
    width: 1220px;
  }
}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

a {
  cursor: pointer;
  user-select: none;
  color: $primary;
  &:visited {
    color: $primary;
  }
}

.error-message,
.empty-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1.1rem 0 0.8rem 0;
}

.empty-message {
  .title,
  .message {
    font-size: 0.875rem;
  }

  .title {
    color: $text-button-secondary;
    font-weight: $bold-text-weight;
    margin-bottom: 0.25rem;
  }

  .message {
    color: $icon-color;
    font-weight: $normal-text-weight;
    text-align: center;
  }
}

.error-boundary {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../assets/background.png') no-repeat;
  background-image: url('../assets/light-background.svg');
  background-color: $white;
  font-size: 1.125rem;
  color: $deep-blue;

  p {
    color: $deep-blue;
    font-family: 'Rubik';
    font-size: 1.125rem;
    font-weight: $normal-text-weight;
  }
}

.cancel-search {
  .MuiIconButton-root:hover {
    background-color: inherit;
  }
}

.table-action-icon {
  &:hover {
    cursor: pointer;
    fill: $border-button-secondary;
  }
}

.table-action-icon-disabled {
  cursor: initial;
  opacity: 0.5;
}
